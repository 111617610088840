import TranslationsEN from './en';

type Translations = typeof TranslationsEN;

type InterpolateInner<
  S extends string,
  U extends object = {},
> = S extends `${string}{{${infer V}}}${infer Rest}`
  ? InterpolateInner<Rest, U & { [K in V]: string }>
  : U;

type Interpolate<S extends keyof Translations> = InterpolateInner<Translations[S]>;

export function t<T extends keyof Translations, Payload = Interpolate<T>>(
  ...args: keyof Payload extends never
    ? [translation: T]
    : [translation: T, payload: Interpolate<T>]
) {
  const translation = TranslationsEN[args[0]];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!translation) {
    return `Invalid translation key: ${args[0]}`;
  }

  let result: string = translation;

  const infiniteLoadingSecurity = {
    max: result.split('{{').length + 1,
    current: 0,
  };

  while (result.includes('{{')) {
    infiniteLoadingSecurity.current += 1;
    if (infiniteLoadingSecurity.current >= infiniteLoadingSecurity.max) {
      break;
    }

    const argsKey = result.match(/{{(.*?)}}/)?.[1];

    if (!argsKey) {
      break;
    }

    if (!args[1]) {
      break;
    }

    const value = (args[1] as Record<string, string>)[argsKey];
    if (typeof value !== 'string') {
      throw new TypeError(`Missing translation for ${argsKey}`);
    }

    result = result.replace(`{{${argsKey}}}`, value);
  }

  return result;
}
