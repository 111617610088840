export default {
  appName: 'Codeline',
  // Base
  title: 'Title',
  actions: 'Actions',
  error: 'Oups, something went wrong.',
  edit: 'Edit',
  customError: 'Error : {{error}}',
  synced: 'Synced',
  notSynced: 'Not synced',
  loading: 'Loading...',
  apiGenericError: 'An error occurred, please try again later.',
  details: 'Details',
  logout: 'Logout',
  pleaseReauthenticate: 'Please re-authenticate.',
  genericNotFound: 'Nothing match your search.',
  free: 'Free',
  users: 'Users',
  login: 'Login',
  home: 'Home',
  contact: 'Contact',
  retry: 'Retry',
  or: 'Or',
  yes: 'Yes',
  continue: 'Continue',
  new: 'New',
  start: 'Get started',
  hidden: 'Hidden',

  success: 'success',
  back: 'Back',
  user: 'User',
  signUp: 'Sign up date',
  customers: 'Customers',
  sales: 'Sales',
  revenue: 'Revenue',
  pending: 'Pending',
  reportBug: 'Report a bug',
  soon: 'Soon',
  beta: 'Beta',
  submit: 'Submit',
  contactUs: 'Contact us',
  description: 'Description',
  options: 'Options',
  generate: 'Generate',
  archive: 'Archive',
  cancel: 'Cancel',
  status: 'Status',
  archived: 'Archived',
  unarchived: 'Unarchived',
  available: 'Available',
  errorOccurred: 'An error occurred, please try again later.',
  save: 'Save',
  draft: 'Draft',
  published: 'Published',
  closed: 'Closed',
  add: 'Add',
  update: 'Update',
  imageSuccessUpload: 'Image uploaded',
  product: 'Product',
  amount: 'Amount',
  refunded: 'Refunded',
  paid: 'Paid',
  paidAt: 'Paid at {{date}}',
  refund: 'Refund',
  progress: 'Progress',
  imageErrorUpload: 'Image upload failed',

  // Form
  required: '{{field}} is required',
  minLength: '{{field}} must be at least {{length}} characters',
  maxLength: '{{field}} must be at most {{length}} characters',

  // Product
  dashboard: 'Dashboard',
  myProducts: 'Products',
  products: 'Products',
  myProductsDescription: 'This is where your journey begins.',
  lessonProduct: '{{name}} • Content',
  editProductContent: 'Edit lessons',
  pendingDescription: 'Revenue that is not yet available.',
  hypotheticalRevenue: 'Hypothetical revenue',
  hypotheticalRevenueDescription:
    'This is the revenue that you could have if all your pending payments were successful.',
  totalRefunds: 'Total refunds',
  monthlyPrice: 'Monthly price',
  editProduct: 'Edit product {{title}}',
  createProduct: 'Create product',
  productStatusInfo:
    'If status is draft, the product is not visible and not accessible (also by the customer).\nIf status is published, the product is visible and customer can buy it.\nIf status is closed, the product is visible by your customer but not accessible.',
  infoMainPrice:
    "The main price of the product, that will be displayed on the product's page.",
  infoMonthlyPrice:
    'The user will be charged this price during X months every month.',
  productOnUserLinked: 'User has now access to the product.',
  productOnUserUnlinked: 'User has no longer access to the product.',
  productOnUserFailed: 'Unable to find the product or the user.',
  // Text to inform that the user has no product
  noProduct: 'You have no product yet.',
  noProductDescription: 'You need to buy your first product to start learning.',
  noProductAdminDescription:
    'You need to create your first product to start editing it.',
  productProgressTitle: 'User progress',
  productProgressDescription:
    'All green element are completed, all the others are not.',
  productCheckoutPage: 'Checkout page',
  productPage: 'Product page',
  // Product discord
  productDiscordTitle: 'Get the {{product}} role',
  productDiscordJoinStep1: `1. Join the discord server.`,
  productDiscordJoinStep2: `2. Enter your username in the field below.`,
  productDiscordCta: 'Get the role',
  roleSuccessfullyAdded: 'Role successfully added for user {{user}}',
  roleErrorAdded: 'The username provider is not valid.',
  roleErrorAddedDescription:
    "Verify that you've joined the server and that your username is correct. Example: Username",
  joinDiscord: 'Join our Discord server',

  // Product finished
  productFinishedTitle: '🎉 Congratulations 🎉',
  productFinishedDescription: 'You finished the course {{productTitle}}.',
  backToProducts: 'Back to my products',

  // Section
  sectionCreated: 'Section created',
  sectionTitle: 'Section title',
  sectionDescription: 'Section description',
  addSection: 'Add section',

  // Lesson
  lessonCreated: 'Lesson created',
  lessonCreatedDescription: "You'll be redirected to the lesson editor.",
  lessonTitle: 'Lesson title',
  lessonDescription: 'Lesson description',
  lessonReordered: 'Lesson reordered',
  lessonHidden: 'Hide lesson',
  lessonFreePreview: 'Free lesson',
  lessonIsFreePreview: 'Lesson is accessible in free preview',
  lessonIsHidden: 'Lesson is hidden',
  addLesson: 'Add lesson',
  uploadVideoSuccess: 'Video uploaded',
  uploadVideoFailed: 'Video upload failed',
  uploadStarted: 'Upload started',
  lessonLockedTitle: 'Lesson locked 😢',
  lessonLockedDescription: 'You need to buy {{product}} to access this lesson.',
  lessonLockedCheckout: 'Buy {{product}}',
  editLesson: 'Edit lesson',
  // Markdown editor
  markdownInvalid: 'Markdown is invalid',
  markdownInvalidDescription:
    'Please check your markdown syntax. You need to close all JSX and HTML tags.',
  unsavedChanges: 'You have unsaved changes, are you sure you want to leave ?',
  lessonActionCompleted: 'Mark as completed',
  lessonActionIdle: 'Mark as in progress',
  lessonActionBookmark: 'Bookmark',

  // User
  userDashboard: 'User {{user}}',
  userHasNoProducts: 'This user has no products.',
  userInfoUpdated: 'Informations have been successfully updated.',
  emailRequired: 'Email is required',
  emailInvalid: 'Email is invalid',
  generalInformations: 'General informations',
  avatar: 'Avatar',
  profileUpdate: 'Update your informations',
  invoicesFullyPaid: 'All invoices are paid',

  paidInvoice: 'Paid invoice',
  invoicesEmpty: 'No Invoices Available Yet',
  invoicesEmptyDescription:
    "It looks like you haven't made any purchases so far. Once you start buying products, all your invoices will be conveniently listed here for easy access and management.",
  downloadInvoice: 'Download invoice',
  invoices: 'Invoices',
  invoicesTotal: '{{totalPaid}} invoices paid for a total of {{total}}',
  invoicesFailed: 'There is {{count}} invoice failed !',
  invoicesRefunded: 'Invoices was refunded',
  paymentFailedCount: 'Try to pay. Already failed {{count}} times.',
  firstNoInvoiceExplanation:
    'Apologies, but due to the limitations of the Stripe payment system, we are unable to provide a download link for the initial invoice.',
  profile: 'Profile',
  removeAccess: 'Remove access',
  giveAccess: 'Give access',
  giveAccessModalTitle: 'Are you sure you want to give access to this user ?',
  giveAccessModalDescription:
    'This user will have access to the product and will be able to see all lessons.',
  removeAccessModalTitle: "Are you sure you want to remove user's access ?",
  removeAccessModalDescription:
    'This user will no longer have access to the product.',
  refundUserModalTitle: 'Are you sure you want to refund this user ?',
  refundUserModalDescription:
    'This user will be refunded and will no longer have access to the product.',

  // Payment
  oneTime: '{{price}}',
  recurring: '{{time}}x monthly payment of {{price}}',
  recurringSimple: '{{time}}x {{price}}',
  choosePaymentType: 'Choose payment type',
  buy: 'Complete your purchase',
  paymentTitle: 'Payment',
  processPaymentError: 'Error while processing payment',
  price: 'Price',
  coupon: 'Coupon',
  total: 'Total',
  totalWithMonthlyPayment:
    'Then, you will be charged {{monthlyPrice}} every month for {{time}} months. (total {{total}})',
  totalForNow: 'You will only be charged {{total}} now.',
  priceHTInformation: 'All prices show here are without taxes (HT).',
  removeCoupon: 'Remove coupon',
  invalidCouponCode: 'Invalid coupon code',
  paymentSafety:
    'Codeline cannot access your bank details as the input is a Stripe integration.',
  problemEncountered: 'Problem encountered ? Contact us at',
  alreadyPurchased:
    'You already purchased this product. You can access it in your dashboard.',
  alreadyPurchasedBundle:
    'You already purschased one of the product in this bundle. You can access it in your dashboard.',
  refundFailed: 'Refund failed',
  analytics: 'Analytics',
  couponNotValid: 'Coupon not valid',
  country: 'Country',
  productNotFound: 'Product not found',
  invalidToken: "The token you're using is invalid.",
  alreadyBuy: 'You already bought this product.',
  alreadyBuyDescription: 'You can access it in your dashboard.',
  addEmailToBuy: 'Add an email to buy this product.',
  subtotal: 'Subtotal',
  waitingPaymentAt: 'Payment at {{date}}',
  successPaymentEmailSubject: 'Yeah, you join {{product}} 💫',
  paymentFailedAt: 'Payment failed at {{date}}',
  successPaymentEmail: `Welcome aboard ! ✨
  
You successfully bought {{product}}. You can access it in your dashboard.

Thanks for your purchase ❤️

I really hope you'll enjoy {{product}} and learn a lot of things.

You can start know by clicking on the link below.

{{productUrl}}

If you have any questions, problems or feedback, you can respond to this email or contact me at
help@codelynx.dev.

To follow the course, you can login with your email {{email}}.

PS: You'll receive an other email from Stripe with your receipt. There is no invoice for this purchase.

Melvynx, Founder of Codelynx`,
  successPaymentAdminEmailSubject: '{{email}} bought {{product}}',
  successPaymentAdminEmail: `New purchase ! ✨

Email : {{email}}
Product : {{product}}
Price : {{price}}
Payment type : {{paymentType}}

Best regards`,

  paymentFailedSubject: '[Action required] Payment failed for {{product}}',
  paymentFailedEmail: `Hello there,

It appears that your most recent invoice for {{product}} is still pending. We understand that life gets busy sometimes, so let's quickly resolve this together!

In the meantime, your access to the product has been temporarily paused, but rest assured, it's an easy fix.

Here's your simple roadmap to regain access to {{product}}:

1. Click this link to handle your pending invoice: {{billingUrl}}
2. Once that's settled, tap on this link to resume your access: {{retryUrl}}
3. Just give the page a quick refresh, and you're all set to dive back in!

We've made this process as straightforward as possible, but if you need any help or have any questions, don't hesitate to reply to this email or reach us at help@codelynx.dev.

We appreciate your understanding, and we're looking forward to welcoming you back to {{product}}.

Stay awesome,

Melvynx
Founder of Codelynx`,
  paymentFailedAdminSubject: '{{email}} payment failed for {{product}}',
  paymentFailedAdminEmail: `Hi,

The payment for {{product}} failed for {{email}}.

The amount was {{amount}}.

We tried to charge {{times}} time(s) this card.

The user account is suspended until the problem is fixed.

Codeline team,`,

  // Suspended page
  suspendedTitle: '{{product}} access was paused 😢',
  suspendedDescription:
    "I'm sorry, but your course access is paused due to a pending payment. Settle your dues to resume your exciting learning journey with us.",
  suspendedDescription2:
    "Once your outstanding payment is settled, your course access will be promptly restored - let's get back to learning!",
  suspendedActionDescription:
    "Simply fulfill your pending invoice, then return to this page and refresh. Voila! Your course access will be instantly restored. Let's continue your learning journey",
  suspendedInfoRecurringPaymentAt: 'Recurring payment date',
  unpaidInvoiceCount: 'Unpaid invoice(s)',
  unpaidInvoiceCountTooltip:
    "You maybe have multiple unpaid invoice. If it's the case, you need to pay all of them to resume your access.",
  suspendedInfoAmount: 'Amount',
  suspendedInfoPaymentFailedAt: 'Payment failed at',
  suspendedInfoPaymentFailedTimes: 'Payment failed time(s)',
  suspendedAction1: '1. Pay the invoice',
  suspendedAction2: '2. Refresh this page',
  suspendedAction3:
    "If the payment doesn't work, contact the support at help@codelynx.dev",
  suspendedPayInvoice: 'Pay invoice',
  suspendedProcessPayment: 'Process payment',
  suspendedResumed: 'Your access has been resumed.',
  suspendedResumedError: 'Error while resuming your access.',
  tooManyFailedPayment: 'Too many failed payment. Contact support.',

  // UserForm
  userFormTitle: `{{username}}'s informations`,

  // Checkout
  thankYouPurchase: "You're all set! 🎉",
  letsBeginProduct: "Let's start {{product}}",
  startLessonNow: 'Start now !',
  validateEmailWarning:
    'You buy a product without account, I just created one for you. To login, you need te receive a ✨ magic link ✨ by clicking on the button below.',
  getMagicLink: 'Get ✨ magic link ✨',
  youEmailIs:
    "Your email is {{email}}. If it's a wrong email, please contact the support at help@codelynx.dev",
  productAlreadyBuy: 'You already bought this product.',
  couponCodeApplied: 'Coupon code applied',
  couponCodeRemoved: 'Coupon code removed',
  checkoutStep1: 'Choose your payment type',
  checkoutStep2: 'Enter your email',
  checkoutStep3: 'Enter your payment information',
  checkoutFirstLoginOrEmail: 'You first need to login or enter your email.',
  invalidEmail: 'Invalid email (example: john@gmail.com)',
  countryNotFieldedTitle: 'Country not fielded',
  countryNotFieldedDescription: 'Please fill your country in the TVA field.',

  // Login
  loginSubtitle: `If you don't have an account, this will create one for you.`,
  signIn: 'Sign in',
  signInGithub: 'Sign in with Github',
  signInGoogle: 'Sign in with Google',
  signInGithubTooltip:
    "You'll be redirected to Github to sign in. The email need to be the same as the one you used to login on Codeline, otherwise it will create a new account.",
  sendMagicLink: 'Send magic link',
  email: 'Email',
  editInfo: 'Edit informations',
  editPaymentMethod: 'Edit payment method',
  githubAccount: 'Linked with Github',
  // Logout
  logoutModalTitle: 'Are you sure you want to logout ?',
  logoutModalDescription:
    'You will be redirected to the homepage and you will need to login again.',
  onSuccessMagicLink: 'You will be redirected...',
  emailNotFieldedTitle: 'Email not fielded',
  emailNotFieldedDescription: 'Please fill your email in the email field.',

  // Coupon
  code: 'Code',
  codeInfo: 'The code that will be used by the customer to apply the coupon.',
  descriptionInfo: 'A internal description of what coupon does.',
  discount: 'Discount',
  usage: 'Usage',
  archivedAt: 'Archived at',
  coupons: 'Coupons',
  editCoupon: 'Edit coupon {{code}}',
  createCoupon: 'Create coupon',
  maxUses: 'Max uses',
  maxUsesInfo:
    "The maximum number of coupon usage. If it's 0 or empty, there is no limit.",
  expireAt: 'Expire at',
  expireAtInfo:
    'The date when the coupon will expire. (you need to define a date to enable it)',
  noExpireAt: 'Never expire',
  couponArchivedDialogTitle: 'Archive coupon `{{code}}`',
  couponArchivedDialogDescription:
    'Are you sure you want to archive this coupon? It will not be available anymore for your users. You can still unarchive it later.',
  generateCodeTooltip: 'It will generate a random and unique code for you.',
  couponDiscountRequired: 'You select a discount {{type}}, you need to define it.',
  couponCodeAlreadyUsed: 'This coupon code is already used.',
  couponProductInfo:
    'Choose a product if you want the coupon ONLY applied to one product.',

  // LoginWithEmailVerification
  verifyYourMail: 'Check your mailbox',
  linkSendEmail: `A login link has been sent to the specified email address.`,
  verifyYourSpam: `If you haven't received it, check your junk folder.`,

  /* --- Components --- */

  // Error
  pleaseLogin: 'Please login to access this page.',
  notAuthorized: 'You are not authorized to access this page.',
  notAuthorizedMessage: 'Please contact us if you think this is a mistake.',
  notLoggedIn: 'You are not logged in.',
  notLoggedInMessage: 'Please login to access this page.',
  notLoggedInAction: 'Login',
  notFound: 'The page you are looking for does not exist.',
  notFoundMessage: 'Please check the URL or go back to the homepage.',
  productPriceUndefined: 'Product price is undefined.',
  unsupportedError: 'Unsupported error',
  unsupportedErrorMessage: 'Please contact us with a screenshot of this error.',
  errorNotLoggedInTitle: 'You are not logged in',
  errorNotLoggedInDescription: 'Please login to access this page.',
  errorNotAuthorizedTitle: 'Sorry, you are not authorized',
  errorNotAuthorizedDescription:
    'You are not authorized to access this page. Please contact us if you think this is a mistake.',
  errorNotFoundTitle: '404 - NotFound',
  errorNotFoundDescription:
    'The page you are looking for does not exist. Please check the URL or go back to the homepage.',
  // You are not authorized or not logged in
  genericError: 'Sorry, an error occurred.',
  genericErrorMessage:
    "The ressource does not exist / you are not authorized to access it / it's just a bug.",

  // Pagination
  prevPage: 'Previous page',
  nextPage: 'Next page',

  // TargetBox
  dragFileHere: 'Drag file here',
  dropFile: 'Time to drop your file',

  // Footer
  allRightReserved: 'All rights reserved',
  copyRight: '© {{year}}-present',

  // ErrorBoundary
  errorBoundaryTitle: 'Oups, something went wrong.',
  errorRetry: 'Retry',
  errorHelpInfo:
    'If the problem persists, please contact us at {{email}} or look at the documentation below.',
  errorDocumentation: 'Documentation',

  /* --- BACKEND --- */

  // Purchases
  noStripeCustomerId: 'No Stripe customer ID',
  undefinedProduct: 'Product not found',
  undefinedPrice:
    'Price is not valid for this product and your payment type. Try another payment type or product owner.',
  productPurchased: 'Product purchased',

  // Coupons
  couponNotFound: 'Coupon not found',
  couponUnarchiveWarning:
    'To unarchive this coupon, we may have changed the maxUsage or expireAt field.',
  couponArchiveSuccess: 'Coupon archived',
  couponArchivedAtDateInPast: 'Coupon archived at date in the past',
  couponAmountOrPercentageRequired:
    'You need to define an amount or a percentage for the discount.',

  // Schools
  schoolNotFound: 'School not found',

  // Feedback
  feedback: 'Give us a feedback',
  feedbackSubTitle: ` We are always looking for ways to improve our lessons. Please let us know your thoughts, that would be very helpful.`,
  yourFeedback: 'Your feedback',
  feedbackMailSuccess: 'Your feedback has been sent, thank you !',
  feedbackMailSubject: 'New feedback for : {{lesson}}',
  feedbackMailContentHtml: `<h1>New feedback for the lesson with id: {{lesson}}.</h1> <br/><br/>

  <i>The member with email : {{email}} has submitted a feedback.</i> <br/><br/>
  
  Here is the feedback : <br/><br/>
  
  {{text}}`,

  // Login mail
  loginMailSubject: 'Here is your magic link ✨',
  loginMailContent: `Hi 👋,

You are trying to login to this {{app}} with email {{email}}.

To login, click on the link below:

{{link}}

If you didn't request this login, please ignore this email.

Have a nice day!`,

  // ReportBug
  reportBugTitle: 'Report a bug',
  imageUploaded: 'Image uploaded :',
  provideScreenshot: 'Provide a screenshot',
  describeIssue: 'Describe the issue',
  reportMailSuccess: 'The bug has been reported. Thank you!',
  reportMailSubject: '[Codeline] A bug has been reported',
  reportMailContentHtml: `<h1>New bug reported in the app 🐛</h1> <br/><br/>

<i>The member with email : {{email}} has reported a bug.</i> <br/><br/>

Here is the description of the bug and an eventual screenshot : <br/><br/>

{{text}} <br/><br/>

<img src="{{image}}" />`,
  reportMailContentDiscord: `**============= Bug reported 🐛 =============**

**User email**
{{email}}

{{other}}

**Message**
{{text}}
{{image}}`,

  // --------- Meta -----------
  metaDescriptionRoot:
    'Codeline is the first learning experience in 4D. You feel everything.',
  metaCheckoutTitle: '{{product}} | Checkout',
  metaCheckoutDescription: "Let's join {{product}} and start learning.",
} as const;
